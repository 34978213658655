<ng-container *ngIf="creditInfo$ | async as creditInfo; else spinnerLoading">
  <div
    class="payment-credit"
    [ngClass]="{
      quote: cart?.isFromQuote,
      'payment-credit-employee': user.isEmployee
    }"
    *ngIf="creditInfo?.availableAmount !== 'undefined' || user.isEmployee"
  >
    <div
      class="payment-credit__error"
      *ngIf="expiredCredit"
    >
      <app-custom-error [expiredCredit]="expiredCredit"></app-custom-error>
    </div>
    <div
      class="payment-credit__error"
      *ngIf="!expiredCredit && !isAvailableCredit && !user.isEmployee"
    >
      <app-custom-error
        [notAvailableCredit]="!isAvailableCredit"
      ></app-custom-error>
    </div>
    <h3 class="payment-credit__title" [ngClass]="{ error: false }">
      {{ "creditPayment.yourLineOfCredit" | cxTranslate }}
    </h3>
    <div
      class="payment-credit__content"
      [ngClass]="{
        expiredCredit: expiredCredit,
        'payment-employee': user.isEmployee
      }"
    >
      <div class="payment-credit__content-value" *ngIf="!user.isEmployee">
        <div
          class="payment-credit__content-value-text"
          [ngClass]="{ error: false }"
        >
          <p>{{ "creditPayment.availableBalance" | cxTranslate }}</p>
          <span *ngIf="!expiredCredit">
            {{ creditInfo?.availableAmount | customCurrency }}
          </span>
          <span *ngIf="expiredCredit"> - </span>
        </div>
      </div>
      <div class="payment-credit__content-value">
        <div
          class="payment-credit__content-value-text"
          [ngClass]="{ error: false }"
        >
          <p>{{ "creditPayment.orderValue" | cxTranslate }}</p>
          <span>
            {{ creditInfo?.totalOrder | customCurrency }}
          </span>
        </div>
      </div>
      <div class="payment-credit__content-value" *ngIf="!user.isEmployee">
        <div
          class="payment-credit__content-value-text"
          [ngClass]="{ error: false }"
        >
          <p>{{ "creditPayment.newBalance" | cxTranslate }}</p>
          <span
            [ngClass]="{ negative: !isAvailableCredit }"
            *ngIf="!expiredCredit"
          >
            {{ creditInfo?.newAmount | customCurrency }}
          </span>
          <span *ngIf="expiredCredit"> - </span>
        </div>
      </div>
      <div class="payment-credit__content-value">
        <div
          class="payment-credit__content-value-text"
          [ngClass]="{
            error: false,
            disabled: lineCreditSelector.status === 'DISABLED'
          }"
        >
          <p
            class="payment-condition"
            [ngClass]="{
              error: false
            }"
          >
            {{ "creditPayment.paymentCondition" | cxTranslate }}
          </p>
          <span *ngIf="user.isEmployee">
            {{ "creditPayment.conditionThirtyDays" | cxTranslate }}
          </span>
        </div>
        <ng-select
          *ngIf="!user.isEmployee"
          [formControl]="lineCreditSelector"
          [clearable]="false"
          [searchable]="false"
          (change)="onChange($event)"
          [placeholder]="'creditPayment.select' | cxTranslate"
          dropdownPosition="bottom"
          [disabled]="expiredCredit"
        >
          <ng-option *ngFor="let item of customCredit" [value]="item">
            {{ item?.name | titlecase }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #spinnerLoading>
  <div class="payment-credit">
    <app-custom-loading-spinner-item></app-custom-loading-spinner-item>
  </div>
</ng-template>
