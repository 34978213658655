import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CustomPinDialogComponent } from 'src/app/common/components/custom-pin-dialog/custom-pin-dialog.component';
import { CustomTwilioValidatorService } from 'src/app/common/services/custom-twilio-validator.service';
import { PromesaRegisterClient } from '../../models/custom-cms-register.interface';
import {
  CustomRegisterEmployee,
  EmployeeRegisterFormData,
} from '../../models/custom-register-employee.interface';
import { CustomNonCustomerFormValidationService } from '../../services/custom-non-customer-form-validation.service';
import { CustomRegisterCustomerValidatorService } from '../../services/custom-register-customer-validator.service';
import { CustomRegisterEmployeeService } from '../../services/custom-register-employee.service';
import { CustomRegisterRegionsService } from '../../services/custom-register-regions.service';
import { CustomRegisterStepOrchestratorService } from '../../services/custom-register-step-orchestrator.service';
import { CustomRegisterEmployeeAccountDialogComponent } from './custom-register-employee-account-dialog/custom-register-employee-account-dialog.component';

@Component({
  selector: 'app-custom-register-employee-account',
  templateUrl: './custom-register-employee-account.component.html',
  styleUrls: ['./custom-register-employee-account.component.scss'],
})
export class CustomRegisterEmployeeAccountComponent
  implements OnInit, OnDestroy
{
  countryCode: string;
  modalRef: ModalRef;
  registerEmployeeForm: FormGroup = this.fb.group(
    {
      email: [
        '',
        [
          Validators.required,
          this.customNonCustomerFormValidationService.customEmailValidation,
        ],
      ],
      cellphone: [
        '',
        [
          Validators.required,
          this.customNonCustomerFormValidationService.customCellhoneValidation,
        ],
      ],
    },
    { updateOn: 'change' }
  );
  private unsubscribe$ = new Subject<void>();
  stepTwo$: Observable<boolean> =
    this.customRegisterStepOrchestrator.getStepTwoStatus();
  clientInfo$: Observable<PromesaRegisterClient | null> =
    this.customRegisterCustomerValidatorService.getClientInfo();
  countryCode$: Observable<string> =
    this.customRegisterRegionsService.getCountryCode();
  subscription: Subscription = new Subscription();

  formData: EmployeeRegisterFormData = {
    ruc: '',
    email: '',
    phone: '',
  };

  constructor(
    private fb: FormBuilder,
    protected modalService: ModalService,
    private cd: ChangeDetectorRef,
    private customRegisterEmployeeService: CustomRegisterEmployeeService,
    private customRegisterStepOrchestrator: CustomRegisterStepOrchestratorService,
    private customNonCustomerFormValidationService: CustomNonCustomerFormValidationService,
    private customRegisterRegionsService: CustomRegisterRegionsService,
    private customRegisterCustomerValidatorService: CustomRegisterCustomerValidatorService,
    private customTwilioValidatorService: CustomTwilioValidatorService
  ) {}

  ngOnInit(): void {}

  validateRegisterEmployeeSubmit(
    client: PromesaRegisterClient,
    countryCode: string
  ) {
    if (this.registerEmployeeForm.invalid) {
      return;
    }
    this.countryCode = countryCode;
    let clientValidate: CustomRegisterEmployee = {
      email: this.registerEmployeeForm.get('email')?.value,
      cellphone:
        countryCode + this.registerEmployeeForm.get('cellphone')?.value,
      customerMail: client.clientEmail,
      clientCode: client.clientCode,
      RUC: client.ruc,
    };
    this.customRegisterEmployeeService
      .validatePromesaClient(clientValidate)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (
          value === 'PromesaInternalCustomerError' ||
          value === 'PromesaB2BNotExistsError'
        ) {
          this.openModalErrorEmail();
        } else {
          this.openPinModal();
        }
      });
  }

  registerEmployee() {
    combineLatest([
      this.countryCode$,
      this.customRegisterCustomerValidatorService.getClientInfo(),
    ])
      .pipe(
        switchMap(([countryCode, clientInfo]) => {
          this.formData.email = clientInfo?.clientEmail!;
          this.formData.phone =
            countryCode + this.registerEmployeeForm.get('cellphone')?.value;
          this.formData.ruc = clientInfo?.ruc!;
          return this.customRegisterEmployeeService.registerPromesaInternalClient(
            this.formData,
            clientInfo?.clientCode!
          );
        })
      )
      .subscribe();
  }

  openModalErrorEmail() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(
      CustomRegisterEmployeeAccountDialogComponent,
      {
        centered: false,
        size: 'lg',
      }
    );
  }

  openPinModal() {
    let modalInstance: any;

    const cellphone =
      this.countryCode + this.registerEmployeeForm.get('cellphone')?.value;

    this.subscription.add(
      this.customTwilioValidatorService
        .getValidationStatus()
        .subscribe((status) => {
          if (status === 'FALSE') {
            this.customTwilioValidatorService.setValidationStatus('');
          } else if (status === 'OK') {
            this.customRegisterStepOrchestrator.setStepTwoStatus(true);
            this.registerEmployee();
          }
        })
    );
    this.subscription.add(
      this.customTwilioValidatorService.sendCodeToPhone(cellphone).subscribe()
    );
    this.modalRef = this.modalService.open(CustomPinDialogComponent, {
      centered: false,
      size: 'lg',
      windowClass: 'twilio',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.cellphone = cellphone;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }
}
