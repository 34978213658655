export const userAccount = {
  loginForm: {
    forgotPassword: '¿Olvidaste tu contraseña?',
    forgotUserOrPassword: '¿Olvidaste tu usuario o contraseña?',
    signIn: 'Ingresar',
    register: 'Registrarse',
    dontHaveAccount: '¿No tienes una cuenta?',
    guestCheckout: 'Guest Checkout',
    registerHere: 'Regístrate aquí',
    employeeAccess: 'Registro colaboradores',
    loginFormTitle: 'Iniciar sesión',
    bottomLoginFormText:
      '1962 - {{currentYear}}, © Productos Metalúrgicos S.A. Todos los derechos reservados.',
    loginFormTitlePdp:
      'Para visualizar el precio del producto, ingresa a tu cuenta de usuario',
    login: 'Iniciar sesión',
    registerLink: 'Registrarme',
    signOut: 'Salir',
    emailAddress: {
      label: 'E-mail',
      placeholder: 'Ingresa tu e-mail',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Ingresa tu contraseña',
    },
    wrongEmailFormat: 'Este no es un formato de correo electrónico válido..',
  },
  miniLogin: {
    userGreeting: 'Hola, {{name}}',
    signInRegister: 'Ingresar / Registrarse',
    myAccount: 'Mi cuenta',
  },
};
